<script lang="ts">
  export let placeholder: string = '';
  export let value: any = null;
  export let readonly: boolean = false;
  import { cn } from 'src/utils/cn';
  export let type: string = 'text';
  function handleInput(e: any) {
    e.target.value = value;
    e.target.type = type;
  }
</script>

<input
  class={cn(
    'input rounded text-neutral-500 bg-primary-white text-base font-normal min-w-screen border-grey',
    `${$$props.class}`,
  )}
  bind:value
  {readonly}
  on:input={handleInput}
  on:input
  on:change
  {placeholder}
/>
